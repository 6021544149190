<template>
  <div class="bg-primary-600 w-full flex md:flex-row flex-col items-end py-4">
    <div class="mx-auto md:max-w-7xl w-full flex md:flex-row flex-col md:justify-end">
      <div class="flex md:flex-row flex-col items-center mx-1">
        <h4 class="leading-8 font-semibold mx-4 md:text-base text-sm md:mx-8">You are viewing our new website experience.
          Like what you see? Go ahead and dismiss
          this banner.</h4>
        <div class="flex space-x-4 text-xs md:text-base md:mt-0 mt-2">
          <BaseButton @click="onSwitch()" class="mx-auto px-6 bg-white" variant="outlined"
            :text="$t('common.returnOldSite')"></BaseButton>
          <BaseButton :text="$t('common.dismiss')" @click="onCancel()"></BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  currentLocale: {
    type: String,
  },
});
const route = useRoute();
const showHeader = ref(true)
const counter = useCookie<any>('counterUser', {
  default: () => null,
  maxAge: 604800,
});
const hideSiteSwitchBanner = useCookie("hideSiteSwitchBanner", {
  maxAge: (30*86400),
  default: () => null,
});


const generateRandomString = () => {
  return Math.floor(Math.random() * Date.now()).toString(36);
};


const randomNumber = generateRandomString();
counter.value = (counter.value == null)? randomNumber:counter.value;

if(counter.value != null && route.query.preview) {
  if(hideSiteSwitchBanner.value == null) {
    await callSwitchApi('in');
  } else {
    navigateTo(localeUri('/'));
  }  
}

const emit = defineEmits(["onCancle"]);

function onCancel() {
  //useCookie('counterUser').value = null
  hideSiteSwitchBanner.value = 1;

  emit("onCancle")
  if(route.path == `/${props.currentLocale}`) {
   return navigateTo(localeUri('/'))
  }

}

async function onSwitch() {
  await callSwitchApi('out');
  if (props.currentLocale == 'en-in') {
    return navigateTo('https://www.better2know.in', { external: true })
  } else {
    return navigateTo('https://www.better2know.com.au/', { external: true })
  }
}
async function callSwitchApi(dataType: string) {
  await useSiteSwitching({
    identifier: counter.value,
    type: dataType
  });
}
</script>